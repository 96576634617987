<div class="autocomplete-container">
  <div class="input-wrapper">
    <input
      type="text"
      *ngIf = "!optionSelected"
      [(ngModel)]="searchQuery"
      (input)="onSearch(searchQuery)"
      [placeholder]="placeHolder"
      class="search-input"
    />
    <div *ngIf = "optionSelected && searchQuery" class = "inner-tab">
      <span  class = "title truncate">{{ searchQuery }}</span> <i class="fas fa-times" (click)="clearSelection()"></i>
    </div>
    <span class="chevron-icon" (click)="toggleDropdown()"></span>
  </div>

  


  <ul *ngIf="filteredOptions.length > 0" class="autocomplete-list">
    <li
      *ngFor="let option of filteredOptions"
      (click)="onSelect(option)"
      [class.selected]="option === searchQuery"
      class="autocomplete-item"
      [ngClass]="{'disabled-option': option === 'No data found'}"
    >
      {{ option }}
    </li>
  </ul>
</div>
