import { Component, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { INotification } from '../../../talent-dashboard/interfaces/notification.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { UtilService } from 'src/modules/shared/services/util.services';
import { findIndex } from 'lodash';
import { NotificationService } from '../../services/notification.service';
import { PopupService } from '../../services/popup.service';
import * as moment from 'moment-timezone';
import { ITalentJob } from 'src/modules/talent-dashboard/interfaces/talent-job.interface';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { IUpdateUser } from 'src/modules/authentication/interfaces/update-user.interface';
import { UserService } from 'src/modules/shared/services/user.service';
import { TalentJobChecksService } from '../../../talent-dashboard/services/talent-job-checks.service';
import { ISetting } from 'src/modules/talent-dashboard/interfaces/settings.interface';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { Store } from '@ngrx/store';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { setAvailableShiftAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { TalentDashboardUtilService } from 'src/modules/talent-dashboard/services/talent-dashboard-util.service';
import { SubSkillService } from '../../services/subskill.service';

@Component({
  selector: 'shared-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnChanges {
  @Input() hour24PayAvailableBadge: boolean;
  @Input() notification: INotification;
  @Input() userData: IUser;
  @Output() acceptNonRecurringBroadcastRequest = new EventEmitter<{
    jobId: string;
    stafferId: string;
    rcmpFeeRequired: boolean;
    skill: string; 
    job: ITalentJob
  }>();
  @Output() rejectStafferBroadcastRequest = new EventEmitter<{
    jobId: string;
    stafferId: string;
    notificationId: string;
  }>();
  @Output() unApplyFromNonRecurringShift = new EventEmitter<{
    jobId: string;
    dueToRCMPFee: boolean;
  }>();
  @Output() seeAvailableJobs = new EventEmitter();
  @Output() confirmNonRecurringShift = new EventEmitter<{
    jobId: string;
  }>();
  @Output() acceptNonRecurringHireRequest = new EventEmitter<{
    jobId: string;
    shiftResponseTime: number;
  }>();

  @Output() rejectNonRecurringJob = new EventEmitter<{
    job: ITalentJob;
  }>();
  @Output() stafferStillWorkingOnNonRecurringJob = new EventEmitter<{
    trackerId: string;
  }>();
  @Output() stopTimerOnNonRecurringJob = new EventEmitter<{
    jobId: string;
    endTime: number;
    userId: string;
    skill: string;
  }>();
  @Output() hireStafferForTheNonRecurringJob = new EventEmitter<{
    job: ITalentJob;
    staffer: IUser;
  }>();
  @Output() submitRating = new EventEmitter<{
    jobId: string;
    stafferId: string;
    rating: number;
    skill: string;
  }>();
  @Output() updateUser = new EventEmitter<IUpdateUser>();
  @Output() hideNotificationDrawer = new EventEmitter();

  @Input() settings: ISetting[];

  isMobile = false;
  jobState: string;
  stafferConfirmedShift: boolean;
  quotaFilled: boolean;
  filedDispute: boolean;
  isAlreadyApplied: boolean;
  isRejected: boolean;
  jobActive: boolean;
  jobOffer: boolean;
  jobAccpted: boolean;
  jobRate: number;
  isIntrestedStaffer: boolean;
  isDisputeButtonCondition: boolean;
  hoursUnConfimred: boolean;
  trackerIsStillWorking: boolean;
  isStafferRejectedShift: boolean;
  
  constructor(
    private popupService: PopupService,
    private utilService: UtilService,
    private router: Router,
    private notificationService: NotificationService,
    private alertController: AlertController,
    private userService: UserService,
    private jobChecksService: TalentJobChecksService,
    private jobFormattingService: TalentJobFormatService,
    private readonly authStore: Store<IAuthState>,
    private talentDashboardUtilService: TalentDashboardUtilService,
    private subSkillService: SubSkillService
  ) {
    this.isMobile = this.utilService.isMobile();
  }

  ngOnChanges() {
    if (this.userData) {
      this.notification.notificationName = this.notificationService.getName(this.notification, this.userData);
      this.notification.showPopup = this.notificationService.showPopup(this.notification);
      this.notification.stafferConfirmedShift = this.notificationService.isStafferConfirmedShift(this.notification, this.userData);
      this.notification.quotaFilled = this.notificationService.isQuotaFilled(this.notification);
      this.notification.stafferHired = this.notificationService.isStafferHired(this.notification, this.notification.user);
      this.notification.jobState = this.notificationService.getJobState(this.notification);
      this.notification.filedDispute = this.notificationService.hasFiledDispute(this.notification);
      this.notification.isAlreadyApplied = this.alreadyApplied();
      this.notification.isRejected = this.rejected();
      this.notification.jobOffer = this.isAJobOffer();
      this.notification.jobAccpted = this.notificationService.isJobAccpted(this.notification, this.userData);
      this.notification.hoursUnConfimred = this.notificationService.isHoursUnConfimred(this.notification, this.userData);
      this.notification.trackerIsStillWorking = this.notificationService.findTrackerIsStillWorking(this.notification, this.userData);
      this.notification.isDisputeButtonCondition = this.checkDisputeButtonCondition();
      this.notification.isIntrestedStaffer = this.checkStatus();
      this.notification.jobRate = (this.userData.type == 'staffer' && this.notification.job) ? (this.notification.job.stafferRate ?  this.notification.job.stafferRate : this.notification.job.rate) : undefined;
      this.notification.isStafferRejectedShift = this.notificationService.isJobRejected(this.notification, this.userData);
  
      if(this.notification.job) {
        if(this.userData.type == 'staffer') {
          this.jobRate = this.notification.job.stafferRate ? this.notification.job.stafferRate : this.notification.job.rate;
        } else {
          this.jobRate = this.notification.job.rate;
        }
      }
      this.jobActive = this.notificationService.isJobActive(this.notification);
    }
  }

  
  async seeDetails() {
    await this.notificationService.showModal(this.notification, this.userData, this.settings, 
      (data) => {
        if(data)  {
        this[data]();
      }
    })
  }

  alreadyApplied() {
    if (
      this.notification.job &&
      this.notification.type === 'broadcast request' &&
      this.notification.creator &&
      this.notification.job.isABroadcastRequest.isTrue &&
      this.notification.job.isABroadcastRequest.interestedStaffers.indexOf(this.userData._id) > -1
    ) {
      return true;
    }
    return false;
  }

  rejected() {
    if (
      this.notification.job &&
      this.notification.type === 'broadcast request' &&
      this.notification.creator &&
      this.notification.job.isABroadcastRequest.isTrue &&
      this.notification.job.isABroadcastRequest.interestedStaffers.indexOf(this.userData._id) > -1
    ) {
      // check if staffer is fired
      const firedIndex = this.notification.job.isABroadcastRequest.broadcastedTo.findIndex(shift => {
        return shift.staffer === this.userData._id && shift.status === 'rejected';
      });

      if (firedIndex > -1) {
        return true;
      }
      // check if vacancies are left
      const vacancies = this.notification.job.isABroadcastRequest.vacancies;

      // check if staffer is hired
      const index = this.notification.job.staffersAndShifts.findIndex(shift => {
        return shift.staffer === this.userData._id;
      });

      if (vacancies > this.utilService.isStaffersConfirmedShifts(this.notification.job.staffersAndShifts).length) {
        return false;
      } else if (index > -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isAJobOffer() {
    let is = false;
    if (this.notification.job && this.notification.user) {
      if (this.notification.type === 'broadcast request' && this.notification.creator) {
        const staffer = this.notification.job.isABroadcastRequest.interestedStaffers;
        if (staffer.length) {
          for (const singleStaffer of staffer) {
            if (singleStaffer === this.notification.user._id) {
              is = false;
              break;
            } else {
              is = true;
            }
          }
        } else {
          is = true;
        }
      } else {
        for (const shift of this.notification.job.staffersAndShifts) {
          if (shift.staffer === this.notification.user._id && shift.state === 'offer' && this.notification.type === 'shift request') {
            is = true;
            break;
          }
        }
      }
    }
    return is;
  }

  verifyHours() {
    this.router.navigateByUrl('talent-dashboard/unverified-hours');
  }

  checkDisputeButtonCondition() {
    return this.utilService.checkDisputeButtonCondition(this.notification.job, this.userData);
  }
  showLocationOnMap(lat: number, lng: number, name: string) {
    this.utilService.launchAddressOnMap(lat, lng, name);
  }

  checkStatus() {
    if (!this.notification.user) {
      return false;
    }

    if (this.notification.job && this.notification.job.isABroadcastRequest.broadcastedTo) {
      const staffers = this.notification.job.isABroadcastRequest.broadcastedTo;
      for (const staffer of staffers) {
        if (this.notification.user._id === staffer.staffer && staffer.status === 'interested') {
          return true;
        }
      }
      return false;
    }
  }
  
  ///////////////////////// buttons events /////////////

  async respondTobroadcastRequest() {
    const jobItem = this.notification.job;

    /**
     * In this function we format the data , redirect to shift detail page and close the notification drawer
     */

    let results =  this.jobFormattingService.formatAvailableShifts([jobItem], this.userData);

    let hasRequiredSubSkills = this.talentDashboardUtilService.hasRequiredSubSkills(this.userData, results[0])

    if (!hasRequiredSubSkills) {
      this.subSkillService.checkSubSkillError({
        error: `You do not have the required subskills (${this.talentDashboardUtilService.getMissingSubSkills(this.userData, results[0]).join(", ")}) to work this shift.`,
      }, this.userData)
      return;
    }

    if(this.hideNotificationDrawer){
      this.hideNotificationDrawer.emit()
    }

    this.router.navigate(['/talent-dashboard/shift-details']);
    this.authStore.dispatch(setAvailableShiftAction({ availableShift: results[0] }));

  }

  checkVSS(cb) {
    let is0k = false;

    if (
      this.userData.vss &&
      this.userData.vss.submitted_on &&
      this.utilService.not12monthsPassed(this.userData.vss.submitted_on) &&
      this.userData.vss.accepted
    ) {
      is0k = true;
    }
    else {
      is0k = false;
   
      this.popupService.showModal(
        {
          heading: 'Vss Attestation',
          message: `Do you attest to the following?`,
          list: [
            `Do you attest that you have not been charged with, or convicted of, a criminal offence that would preclude you from working with, or around a “vulnerable person",   as defined in section 6.3 of the Criminal Records Act, R.S.C. 1985, c. C-47 as “… a person who, because of his or her age,    a disability or other circumstances, whether temporary or permanent`,
            `(a) is in a position of dependency on others; or `,
            `(b) is otherwise at a greater risk than the general population of being harmed by a person in a position of trust or authority towards them?`
          ],
          btn: 'Yes, you attest',
          rejectBtnText: 'No, you don\'t attest',
          navigateRoute: null,
          imgURL: null
        },
        async accept => {
          await this.updateUser.emit({
            vss: {
              accepted: true,
              submitted_on: moment().unix()
            }
          });
          this.popupService.showModal(
            {
              heading: 'Attestation accepted.',
              message: 'You can continue applying jobs now',
              btn: 'Ok',
              navigateRoute: null,
              imgURL: 'assets/images/thumbs-up.png'
            },
            () => {
              cb();
            }
          );
        },
        async reject => {
          this.popupService.showModal({
            heading: 'VSS Attestation',
            message: `You must attest that you're free of criminal convictions to apply to this position`,
            btn: 'Ok',
            navigateRoute: null,
            imgURL: 'assets/images/profile.png'
          });
        }
      );
    }
    return is0k;
  }
  continueApplyingNonRecur() {
    this.notification.disable = true;

    let isRespondingAgain = false;

    for (const staffer of this.notification.job.isABroadcastRequest.interestedStaffers) {
      if (staffer === this.notification.user._id) {
        isRespondingAgain = true;
        break;
      }
    }
    if (isRespondingAgain) {
      this.popupService.showModal({
        heading: 'Shift Requested',
        message: 'You have already responded to this job request.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
    } else if (!this.userData.hasAccessToApplyOnShifts) {
      this.popupService.showModal({
        heading: 'Please sign the Waiver of Liability',
        message: 'To be able to apply to this shift, you will need to sign the Waiver of liability.',
        btn: 'Ok',
        navigateRoute: 'talent-dashboard/hello-sign',
        imgURL: 'assets/images/sign.png'
      });
    } else if (
      this.notification.job.vunerableCheckRequired && 
      !this.utilService.isVSCRequired(this.notification.job.skill) &&
      !this.userData.rcmpVerification.verified &&
      !this.userData.rcmpVerification.invitationSent &&
      !this.userData.byPassRCMPVerification
    ) {
      this.askForRCMP();
    } else {
      this.validateAndAccept(false);
    }
    this.notification.disable = false;
  }

  validateAndAccept(rcmpFeeRequired) {
    if (this.notificationService.validateShiftEndTime(this.notification.job.shiftEndTime)) {
      this.acceptNonRecurringBroadcastRequest.emit({
        jobId: this.notification.job._id,
        stafferId: this.notification.user._id,
        rcmpFeeRequired: rcmpFeeRequired,
        skill: this.notification.job.skill,
        job: this.notification.job
      });
    } else {
      this.popupService.showModal({
        heading: 'Sorry',
        message: 'Sorry, this shift is no longer available',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
    }
  }

  async askForRCMP() {
    const alert = await this.alertController.create({
      header: 'RCMP check required',
      cssClass: 'alert-modal',
      message: `Please note this client required an RCMP check in order to qualify.
       There will be a $50 charge that will charged to your next invoice. 
       You'll also be emailed a copy, and a badge will appear on your profile. This is a one-time charge.`,
      buttons: [
        {
          text: 'Reject',
          cssClass: 'danger',
          handler: () => {
            this.unApplyFromShift(true);
          }
        },
        {
          text: 'Accept',
          handler: () => {
            // this.updateUser.emit({ rcmpFeeRequired: true });
            this.validateAndAccept(true);
          }
        }
      ]
    });

    await alert.present();
  }

  rejectStaffer() {
    this.rejectStafferBroadcastRequest.emit({
      jobId: this.notification.job._id,
      stafferId: this.notification.user._id,
      notificationId: this.notification._id
    });
  }

  unApplyFromShift(dueToRCMPFee = false) {
    this.unApplyFromNonRecurringShift.emit({
      jobId: this.notification.job._id,
      dueToRCMPFee
    });
  }
  seeAvailableShifts() {
    this.seeAvailableJobs.emit();
  }
  
  confirmShift() {
    this.confirmNonRecurringShift.emit({ jobId: this.notification.job._id });
  }

  acceptHireRequest() {
    if (this.notificationService.validateShiftEndTime(this.notification.job.shiftEndTime)) {
      this.notification.disable = true;
      this.acceptNonRecurringHireRequest.emit({
        jobId: this.notification.job._id,
        shiftResponseTime: moment().diff(moment(this.notification.created))
      });
      this.notification.disable = false;
    } else {
      this.popupService.showModal({
        heading: 'Sorry',
        message: 'Sorry, this shift is no longer available',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
    }
  }

  rejectJob() {
    this.rejectNonRecurringJob.emit({
      job: this.notification.job
    });
  }

  stillWorkingStaffer() {
    const currentUserIndex = findIndex(this.notification.job.staffersAndShifts, ['staffer', this.userData._id]);

    if (this.notification.job.staffersAndShifts[currentUserIndex].tracker.state !== 'stopped') {
      const trackerId = this.notification.job.staffersAndShifts[currentUserIndex].tracker._id;
      this.stafferStillWorkingOnNonRecurringJob.emit({
        trackerId
      });
    } else {
      this.popupService.showModal({
        heading: 'End Shift Request',
        message: 'Shift already ended.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/profile.png'
      });
    }
  }

  stopTrackerNow() {
    const currentUserIndex = findIndex(this.notification.job.staffersAndShifts, ['staffer', this.userData._id]);

    if (this.notification.job.staffersAndShifts[currentUserIndex].tracker.state !== 'stopped') {
      this.stopTimerOnNonRecurringJob.emit({
        jobId: this.notification.job._id,
        endTime: +moment().tz(this.notification.job.timeZone).format('X'),
        userId: this.userData._id,
        skill: this.notification.job.skill
      });
    } else {
      this.popupService.showModal({
        heading: 'End Shift Request',
        message: 'Shift already ended.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/profile.png'
      });
    }
  }

  // hire staffer who had applied for broadcast job
  hireStafferForJob() {
    if (this.notificationService.validateShiftEndTime(this.notification.job.shiftEndTime)) {
      this.hireStafferForTheNonRecurringJob.emit({
        staffer: this.notification.user,
        job: this.notification.job
      });
    } else {
      this.popupService.showModal({
        heading: 'Sorry',
        message: 'Sorry, this shift is no longer available',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
    }
  }

  rateStaffer() {
    this.popupService.showStafferRatingPopup(
      {
        name: this.notification.notificationName,
        message: 'Please review and rate the staffer'
      },
      (cb: { data: { rating: number } }) => {
        if (cb.data.rating) {
          this.submitRating.emit({
            jobId: this.notification.job._id,
            stafferId: this.notification.user._id,
            rating: cb.data.rating,
            skill: this.notification.job.skill
          });
        }
      }
    );
  }
  fileDispute() {
    this.notificationService.disputeToFileData = {
      jobId: this.notification.job._id,
      skill: this.notification.job.skill,
      staffer: this.notification.user
    };
    this.router.navigateByUrl(`/company-dashboard/file-dispute`);
  }
 
}
