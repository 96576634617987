import { Component, Input, Output, EventEmitter, OnInit, OnChanges, ElementRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

@Component({
  selector: 'app-autocomplete-search',
  templateUrl: './autocomplete-search.component.html',
  styleUrls: ['./autocomplete-search.component.scss'],
})
export class AutocompleteSearchComponent implements  OnChanges {
  @Input() options: string[] = []; // Input array of data
  @Output() selected = new EventEmitter<string>(); // Emit selected item
  @Input() placeHolder: string = ''; // Placeholder text
  optionSelected : boolean = false;


  @Input() searchQuery: string = ''; // Input value
  filteredOptions: string[] = []; // Filtered results
  private searchSubject = new Subject<string>(); // Subject for debounce

  constructor(private el: ElementRef) {
    // Set up a debounce mechanism for search
    this.searchSubject
    .pipe(
      debounceTime(300),
      startWith(''), // Ensures the initial value is handled
      switchMap((query) => {
        return of(query).pipe(tap((q) => this.filterOptions(q)));
      })
    )
    .subscribe();
  }

  ngOnChanges() {
    // Update the filtered options when the options array changes
    if(!this.searchQuery){
      this.optionSelected  = false; 
    }
  }


  onSearch(query: string) {
    this.searchSubject.next(query); // Emit value for debounce handling
  }

  ngAfterViewInit() {
    // Set up click outside detection after view initialization
    document.addEventListener('click', this.onClickOutside.bind(this));
  }

  ngOnDestroy() {
    // Clean up event listener when the component is destroyed
    document.removeEventListener('click', this.onClickOutside.bind(this));
  }

  onClickOutside(event: MouseEvent) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  filterOptions(query: string) {
    if (!query.trim()) {
      this.filteredOptions = [];
      this.selected.emit(''); // Emit empty string if query is empty
      return;
    }

    // Filter the options array based on the query
    this.filteredOptions = this.options.filter((option) =>
      option.toLowerCase().includes(query.toLowerCase())
    );

    if(!this.filteredOptions.length) {
      this.filteredOptions = ['No data found'];
    }
  }

  onSelect(option: string) {
    this.selected.emit(option); // Emit the selected option
    this.searchQuery = option; // Update input value
    this.filteredOptions = []; // Clear suggestions
    this.optionSelected = true;
  }

  clearSelection() {
    this.searchQuery = '';
    this.optionSelected = false;
    this.selected.emit(''); // Emit empty string
    this.filteredOptions = []
  }

  toggleDropdown() {
    if (this.filteredOptions.length > 0) {
      this.filteredOptions = []; // Close dropdown if open
    } else {
      // If the user hasn't typed anything, show all options
      this.filteredOptions = this.options.slice();

      if(!this.options.length) {
        this.filteredOptions = ['No data found'];
      }
    }
  }

  private closeDropdown() {
    this.filteredOptions = []; // Close dropdown if open

  }
}
