import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SuccessPopupComponent } from '../components/success-popup/success-popup.component';
import { StafferRatingPopupComponent } from '../components/staffer-rating-popup/staffer-rating-popup.component';
import { DetailPopupComponent } from '../components/detail-popup/detail-popup.component';
import { Router } from '@angular/router';
import { FullDetailComponent } from '../components/full-detail/full-detail.component';
import { InvoicesService } from './invoices.service';
import { SelectExperienceComponent } from 'src/modules/onboarding-staffer/components/select-experience/select-experience.component';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { IStafferInvoice } from 'src/modules/onboarding-staffer/interfaces/invoice.interface';
import { ITalentJob } from 'src/modules/talent-dashboard/interfaces/talent-job.interface';
import { BulletinsComponent } from '../bulletins/bulletins.component';
import { IBulletin } from '../interfaces/bulletin.interface';
import { ServiceFeeUpdateNoticeComponent } from '../components/service-fee-update-notice/service-fee-update-notice.component';
import { WithdrawAmountComponent } from 'src/modules/talent-dashboard/components/withdraw-amount/withdraw-amount.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  constructor(private alertController: AlertController, private modalController: ModalController, private router: Router, private invoicesService: InvoicesService) {
  
  }

  async showPopup(header: string, message: string, btnText: string = 'OK') {
    const alert = await this.alertController.create({
      header,
      message,
      cssClass: 'showPopup',
      buttons: [btnText]
    });
    await alert.present();
  }


  async showModal(
    // msgType: ModalTypeEnum,
    data: {
      heading: string;
      message: string;
      rejectBtnText?: string;
      btn: string;
      navigateRoute: string;
      imgURL: string;
      list?: string[];
      class?: boolean;
      specificClass?:string;
      externalNavigationUrl?: string;
      disableSubmission?: boolean;
      component?: any;
      iconClass?: string;
      showDismissButton?: boolean;
    },
    cb?,
    cb2?
  ) {
    const myModal = await this.modalController.create({
      component: data.component? data.component : SuccessPopupComponent,
      backdropDismiss: false,
      cssClass: data.specificClass ?  data.specificClass : data.class ? 'certificatesModal' : 'wideModal',
      componentProps: {
        // msgType,
        data
      }
    });
    
      await myModal.present();  
      return await myModal.onDidDismiss().then(dismiss => {
        if (dismiss.data === 'accept') {
          cb && cb();
        } else if (dismiss.data === 'reject') {
          cb2 && cb2();
        } else cb && cb();
      });
  }

  async showCustomComponentModal(
    data: {
      component: any,
      customComponentParams?: {[key: string]: any},
      [key: string]: any
    },
    cb?,
    cb2?
  ) {
    const { customComponentParams, modalId } = data;
    const myModal = await this.modalController.create({
      component: data.component,
      backdropDismiss: data?.backdropDismiss || false,
      cssClass: data?.cssClass || 'wideModal',
      componentProps: {
        data: customComponentParams,
      },
      id: modalId,
    });

    await myModal.present();  
    return await myModal.onDidDismiss().then(dismiss => {
      if (dismiss.data === 'accept') {
        cb && cb();
      } else if (dismiss.data === 'reject') {
        cb2 && cb2();
      } else cb && cb();
    });
  }
 
  async showExperienceModal(
  data: {
      heading: string;
      list: any,
      activated: any
    },
    cb?,
    cb2?
  ) {
    const myModal = await this.modalController.create({
      component: SelectExperienceComponent,
      backdropDismiss: false,
      cssClass: 'wideModal',  
      componentProps: {
        data
      }
    });
    await myModal.present();
    return await myModal.onDidDismiss().then(dismiss => {
      if (dismiss.data === 'accept') {
        cb && cb();
      } else if (dismiss.data === 'reject') {
        cb2 && cb2();
      } else cb && cb(dismiss);
    });
  }

  async showDetails(data, cssClass? : string) {
    const myModal = await this.modalController.create({
      component: DetailPopupComponent,
      backdropDismiss: false,
      cssClass: cssClass ? cssClass : 'wideModal',
      componentProps: {
        data
      }
    });
    await myModal.present();
    return await myModal.onDidDismiss().then(dismiss => {
    });
  }

  async showStafferRatingPopup(data: { name: string; message: string }, cb: Function) {
    const myModal = await this.modalController.create({
      component: StafferRatingPopupComponent,
      backdropDismiss: false,
      cssClass: 'wideModal',
      componentProps: {
        // msgType,
        data
      }
    });
    await myModal.present();
    return await myModal.onDidDismiss().then(data => {
      if (cb) {
        cb(data);
      }
    });
  }

  async recurringWarning() {
    return new Promise((resolve, reject) => {
      this.showModal(
        {
          heading: 'Notice',
          message:
            'By pressing the Confirm button below, you are accepting all the posted shifts and dates. \n Failure to complete all your shifts may result in deactivation from the Staffy platform.',
          btn: 'Confirm',
          rejectBtnText: 'Reject',
          navigateRoute: null,
          imgURL: 'assets/images/notice.png'
        },
        () => {
          // 'confirm';
          resolve(true);
        },
        () => {
          //'cancel';
          resolve(false);
        }
      );
    });
  }

  async fullDetailModal(data: {job: ITalentJob, user: IUser, currentInvoice?: IStafferInvoice}, markAsPaid, cb?) {
    const myModal = await this.modalController.create({
      component: FullDetailComponent,
      backdropDismiss: true,
      cssClass: 'shiftDetailModal',
      componentProps: {
        data
      }
    });
    await myModal.present();
    return await myModal.onDidDismiss().then(dismiss => {
      if (dismiss.data && dismiss.data.download === true) {
        this.invoicesService.download(data.currentInvoice, true);
      } else if (dismiss.data && dismiss.data.markedAsPaid === true) {
        markAsPaid();
      } else if ( dismiss.data && dismiss.data.invoicePaid === true && cb) {
        cb()
      }
    });
  }

  async showScreeningPopup(link, entityName){
    await this.showModal({
      heading: "Shift requested",
      message: `You have successfully requested this shift. We will send you a notification if you are approved. ${entityName} requires a one-on-one call before hiring you for this your shift`,
      btn: "Book Call",
      rejectBtnText: "Not Now",
      imgURL: "assets/images/calender2.png",
      navigateRoute: null,
      externalNavigationUrl: link,
    }); 
  }
  
  
  async viewShiftBulletins(bulletins :IBulletin ,entityId : string) {
    const myModal = await this.modalController.create({
      component: BulletinsComponent,
      backdropDismiss: false,
      cssClass: 'wideModal',
      componentProps: {
        bulletins,
        entityId
      }
    });
    return await myModal.present();
  }

  async showPlatformServiceFeeUpdateNotice(staffer: IUser, cb?) {
    const myModal = await this.modalController.create({
      component: ServiceFeeUpdateNoticeComponent,
      backdropDismiss: true,
      cssClass: 'wideModal',
      componentProps: {
        staffer
      }
    });
    await myModal.present();
    return await myModal.onDidDismiss().then(data => {
      if (cb) {
        cb(data);
      }
    });
  }
}
