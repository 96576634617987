<ion-menu menuId="side-menu" swipeGesture='false' side="start" type="overlay" auto-hide="false" #menu>
  <ion-content class="side-menu">
    <div class="flex-container-list">
      <div>
        <ion-list class="no-border">
          <ion-item slot="fixed" class="no-border">
            <div class="flex">
              <div class="icon-cancel"><i (click)="closeMenu()" class="fa fa-times"></i></div>
              <div class="detail">
                <div class="staffer-pic-div" *ngIf="user.profileUrl?.url">
                  <img *ngIf="!user && user.badges.indexOf('covid-19-vaccine') == -1" class="user-img"
                    src="{{user.profileUrl?.url}}">

                  <div *ngIf="!user.profile_approved && user.badges.indexOf('covid-19-vaccine') > -1"
                    style="width: 100%;">
                    <img class="user-img" src="{{user.profileUrl?.url}}">
                    <div class="staffy-verified-covid">
                      <img src="assets/images/Covid-Badge.svg">
                    </div>
                  </div>

                  <div *ngIf="user.profile_approved" class="staffer-verified">
                    <img class="user-img" style="border: 0 !important; padding: 0 !important"
                      src="{{user.profileUrl?.url}}">

                    <img class="verification-icon" src='assets/images/staffy_verified.png'>

                    <div class="staffy-verified-covid" *ngIf="user.badges.indexOf('covid-19-vaccine') > -1">
                      <img src="assets/images/Covid-Badge.svg">
                    </div>
                  </div>
                </div>
                <div class="user-details">
                  <h1> {{ user.firstName }} {{ user.lastName }}</h1>
                  <p class="rating"><i class="fa fa-star"></i>{{ (user.aggregated_rating)?.toFixed(2) || 0 }}</p>
                </div>
              </div>
              <div class="performance-metrices">
                <shared-signals-indicators class="performance-metrices" [userData]="user"
                  [showTotalShifts]="true"></shared-signals-indicators>
              </div>
            </div>
          </ion-item>
          <ion-item *ngIf="showBackBtn" (click)="navBack()" class="active-text no-border">
            <i class="fas fa-chevron-left"></i><span *ngIf="activeMenu?.expand"><i class="active-icon"
                [ngClass]="activeMenu?.icon"></i>{{ activeMenu?.title }}</span>
          </ion-item>
        </ion-list>
      </div>

      <div>
        <ion-list [ngClass]="{'no-top-margin' : showBackBtn}">
          <ng-container *ngFor="let page of appPages">
            <ng-container
              *ngIf="page.show && ((page.forApprovedUserOnly && user.profile_approved) || !page.forApprovedUserOnly)">
              <ion-item
                *ngIf="((page.forNonApprovedUserOnly && !user.profile_approved) || !page.forNonApprovedUserOnly)"
                [ngClass]="{'hide-mob': page.mobHidden}" class="menu-item" [routerDirection]="'root'"
                (click)="navigate(page)">

                <i class="menu-icon" *ngIf="page.icon" [ngClass]="page.icon"></i>

                <span *ngIf="page.title == 'Requests' && totalRequestCount && totalRequestCount != 0"
                  class="requestCount">{{ totalRequestCount }}</span>

                <span *ngIf="page.title == 'Open Requests'" class="requestCount">{{ openRequestCount }}</span>

                <span *ngIf="page.title == 'Filled Requests'" class="requestCount">{{ filledRequestCount}}</span>

                <ion-label class="menu-text" [ngClass]="{'left-spacing' : !page.icon}">
                  <span *ngIf="page.title != 'Wallet'">
                    {{ page.title }}
                  </span>

                  <span *ngIf="page.title == 'Wallet'">{{ page.title }}</span>
                </ion-label>

                <span *ngIf="page.expand">
                  <i class="fa fa-chevron-right"></i>
                </span>

              </ion-item>
            </ng-container>
          </ng-container>
        </ion-list>
      </div>

      <div class="no-border align-to-end">
        <div class="reserved-rights">
          <h1 [ngClass] = "{'healthcare-reserved-logo': (logo?.includes('healthcare'))}" >All rights reserved - <img [src]="logo" alt="Staffy" class="reserved-logo"></h1>
        </div>
      </div>
    </div>
  </ion-content>
</ion-menu>

<ion-router-outlet [ngClass]="{'visible-top' : !isOnline}" main></ion-router-outlet>