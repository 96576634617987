<div class="patient-form-wrapper">
  <a class="close" (click)="closeModal()">&times;</a>
  <h1>Add New Patient</h1>

  <label>Patient ID</label>
  <input (change)="handlePatientChange()" [(ngModel)]="name" type="text" class="form-input">
  
  <label>Postal Code</label>
  <app-places-autocomplete class="auto-input form-input" [formatedAddress]="patientAddress?.street" [placeholder]="''" (addressChanged)="getAddress($event)"></app-places-autocomplete>

  <div class="error" *ngIf="errMsg">
    {{errMsg}}
  </div>
  <br>
  
  <button class="btn" (click)="addNewPatient()" *ngIf="!patientId">Save</button>
  <button class="btn" (click)="updatePatient()" *ngIf="patientId">Update</button>
</div>